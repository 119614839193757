import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import moment from "moment";

class Profile extends Component {

  constructor() {
    super();
    this.state = {
      searchedOrder: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      searchedOrder: e.target.value,
    });
  };
  render() {
    const { auth, profile, orders } = this.props;

    let myOrders = [];
    if (orders && auth) {
      orders.forEach((order) => {
        if (order.customer_id === auth.uid) {
          myOrders.push(order);
        }
      });
    }

    if (!auth.uid) {
      window.$('#loginModal').modal('show');

    }
    if (!auth.uid) return <Redirect to="/" />;


    return (
      <div className="container mt-5 pt-5">
        <div className="row">
          <div className="col-8">
            <div className="rounded-circle profile-avatar float-left">
              <span className="initials">{profile && profile.initials}</span>

            </div>
            <div className="welcome-message float-right">
              Welcome <br></br> {profile && profile.firstName}
            </div>


          </div>


        </div>
        <div className="row mt-5">
          <ul className="list-group w-100 profile-orders">
            <li className="list-group-item active" style={{ backgroundColor: "transparent", border: "none" }}>My Orders</li>
            <li className="list-group-item container">
              <div className="row p-2">
                <div className="col-4">
                  Date

                </div>
                <div className="col-6">
                  Order Number

                </div>

                <div className="col-2">
                  Price

                </div>

              </div>
            </li>
            {
              myOrders && myOrders.length > 0 ? myOrders.map(order => (<li className="list-group-item list-group-item-action" key={order.id} >

                <div className="row mb-2 p-2" key={order.id} style={{ cursor: 'pointer' }}>
                  <div className="col-4 text-break" style={{ fontSize: "12px" }}>
                    {moment(new Date(order.createdAt.seconds * 1000)).format(
                      "LLL"
                    )}

                  </div>
                  <div className="col-6 text-break" style={{ fontSize: "12px" }}>
                    {order.id}<br />
                    <span className="text-success mt-2 pt-2" onClick={() => this.props.history.push(`/order/${order.id}`)}>view details<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
</svg></span>



                  </div>
                  <div className="col-2 text-break" style={{ fontSize: "12px", paddingRight: "10px" }}>
                    {order.currency === "USD" ? "$" : "kes"}{order.totalAmount}

                  </div>

                </div>




              </li>
              )) : null}


          </ul>



        </div>


      </div>

    );
  }
}

const mapStateToProps = (state) => {

  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    orders: state.firestore.ordered.orders,
  };
};
export default withRouter(compose(
  connect(mapStateToProps),
  firestoreConnect(() => [
    {
      collection: "orders",
      orderBy: ["createdAt", "desc"]

    },
  ])
)(Profile));
