import React, { Component } from "react";
import { addItemToCart } from "../store/actions/cartActions";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Homepage.css";

class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      addedToCartAlert: false,
      outOfStockAlert: false,
      is_checked: false,
      currentCountry: "$",
    };
  }
  componentDidMount() {
    window.$(".productcarousel").carousel();
    window.$(".carouselHeader").carousel();
  }
  handleChange = (e) => {
    this.setState({
      is_checked: e.target.checked,
    });
  };

  addingItemToCart = (item) => {
    let newItem = { ...item };

    window.$('[data-toggle="tooltip"]').tooltip("hide");
    let codesF = [];

    if (this.props.codes) {
      this.props.codes.forEach((derived) => {
        if (
          derived.product.toLowerCase() === newItem.productName.toLowerCase() &&
          derived.price === newItem.price &&
          derived.currency === newItem.currency
        ) {
          if (derived.status === false) {
            codesF.push(derived);
          }
        }
      });
    }
    if (this.props.cartItems.length === 0) {
      if (codesF.length === 0) {
        toast.error(`item is out of stock!`);

        return;
      } else {
        toast.dark(`item added to cart!`);
        this.props.addItem(item);
      }
    } else if (this.props.cartItems) {
      if (codesF.length === 0) {
        toast.error(`item is out of stock!`);
        return;
      } else {
        const comparedItem = this.props.cartItems.find(
          (cartItem) => cartItem.id === newItem.id
        );

        if (comparedItem && comparedItem.quantity < codesF.length) {
          toast.dark(`item added to cart!`);
          this.props.addItem(item);
        } else {
          if (this.props.cartItems) {
            const comparedItemm = this.props.cartItems.find(
              (cartItem) => cartItem.id === newItem.id
            );
            if (comparedItemm && comparedItemm.quantity >= codesF.length) {
              toast.error(`item is out of stock!`);
              return;
            } else {
              toast.dark(`item added to cart!`);
              this.props.addItem(item);
            }
          }
        }
      }
    }
  };

  render() {
    const { products } = this.props;
    let psn = [];

    // displaying the products in ascending order
    if (products) {
      psn = products
        .filter((p) => p.productName === "Psn")
        .sort((a, b) => a.price - b.price);
    }

    return (
      <div className="">
        {/* Start alert */}
        <ToastContainer hideProgressBar={true} position="top-right" />

        <div className="jumbotron main-header"></div>
        <div className="jumbotron bg-transparent mb-3 d-none d-md-none d-lg-block">
          <h1 className="text-center text-light display-4">
            Africa's gateway to a world of play
          </h1>
          <div className="row mx-auto" style={{ width: "50%" }}>
            <div className="col ml-4 pb-5" style={{ margin: "0 auto" }}>
              <div className="row pt-4 text-light" style={{ width: "100%" }}>
                <div className="mx-auto">
                  <h6 className="text-center">Shop Playstation</h6>
                  <h6 className="text-center">Gift cards</h6>
                  <ul
                    role="tablist"
                    className="nav bg-transparent nav-pills  nav-fill mb-3 mx-auto"
                  >
                    <li
                      className="nav-item"
                      onClick={() => {
                        this.setState({
                          currentCountry: "$",
                        });
                      }}
                    >
                      <a
                        data-toggle="pill"
                        href="#credit-card"
                        className="nav-link active"
                      >
                        US
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        this.setState({
                          currentCountry: "R",
                        });
                      }}
                    >
                      <a
                        data-toggle="pill"
                        href="#paypal"
                        className="nav-link "
                      >
                        South Africa
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        this.setState({
                          currentCountry: "£",
                        });
                      }}
                    >
                      <a
                        data-toggle="pill"
                        href="#net-banking"
                        className="nav-link "
                      >
                        UK
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row" style={{ width: "100%" }}>
                {psn && psn
                  ? psn
                      .filter(
                        (ps) =>
                          ps.currency.toUpperCase() ===
                          this.state.currentCountry
                      )
                      .map((p, index) => (
                        <div
                          className={`col col-sm-4 ${
                            index > 2 ? "mt-4" : null
                          }`}
                          key={p.id}
                        >
                          <img
                            src="/playstation.png"
                            style={{ height: "189px", width: "131px" }}
                            alt=""
                          />
                          <button
                            type="button"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Add to cart"
                            className="btn p-2 rounded-circle product-card psn-item"
                            onClick={() => this.addingItemToCart(p)}
                          >
                            {p.currency}
                            {p.price}
                          </button>
                        </div>
                      ))
                  : null}
              </div>
            </div>
          </div>
        </div>
        {/* Mobile */}
        <div
          className="jumbotron bg-transparent d-md-block d-lg-none"
          style={{ minHeight: "75vh", maxHeight: "75vh" }}
        >
          <h6 className="text-center text-light" style={{ fontSize: "rem" }}>
            Africa's gateway to a world of play
          </h6>
          <div className="mx-auto">
            <h6 className="text-center text-white">Shop Playstation</h6>
            <h6 className="text-center text-white">Gift cards</h6>
            <ul
              role="tablist"
              className="nav bg-transparent nav-pills  nav-fill mb-3 mx-auto"
            >
              <li
                className="nav-item"
                onClick={() => {
                  this.setState({
                    currentCountry: "$",
                  });
                }}
              >
                <a
                  data-toggle="pill"
                  href="#credit-card"
                  className="nav-link active"
                >
                  US
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.setState({
                    currentCountry: "R",
                  });
                }}
              >
                <a data-toggle="pill" href="#paypal" className="nav-link ">
                  South Africa
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.setState({
                    currentCountry: "£",
                  });
                }}
              >
                <a data-toggle="pill" href="#net-banking" className="nav-link ">
                  UK
                </a>{" "}
              </li>
            </ul>
          </div>

          {/* carousel */}
          <div
            id="carouselExampleControls2"
            className="carousel  productcarousel mt-2 slide"
            data-ride="productcarousel"
          >
            <div className="carousel-inner card-container-wrapper">
              {psn && psn
                ? psn
                    .filter((ps) => ps.currency === this.state.currentCountry)
                    .map((item, index) => (
                      <div
                        className={`carousel-item ${
                          index === 0 ? "active" : null
                        }`}
                        key={item.id}
                      >
                        <img
                          src="/playstation.png"
                          className="d-block w-50 mx-auto"
                          alt="..."
                        />
                        <button
                          type="button"
                          onClick={() => this.addingItemToCart(item)}
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Add to cart"
                          className="btn p-2 rounded-circle product-card sp-item text-light psn-mobile"
                          style={{ left: "45%", top: "80%" }}
                        >
                          {item.currency}
                          {item.price}
                        </button>
                      </div>
                    ))
                : null}
            </div>
            <a
              className="carousel-control-prev mr-1"
              href="#carouselExampleControls2"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next ml-1"
              href="#carouselExampleControls2"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
          {/* End of carousel */}
        </div>
        {/* end of mobile */}
        <div className="jumbotron social-media-section">
          <h6 className="text-center text-white social-media-heading d-none d-md-block">
            Join our ever growing community of players
          </h6>
          <h6
            className="text-center text-white social-media-heading d-block d-md-none"
            style={{ fontSize: "26px" }}
          >
            Join our ever growing community of players
          </h6>
          <div className="social-media-description d-none d-md-block">
            Utally continues to break boundaries by enabling quick
            <br />
            and easy access to Africa's favourite places to play.
          </div>
          <div
            className="social-media-description d-block d-md-none"
            style={{ fontSize: "14px" }}
          >
            Utally continues to break boundaries by enabling quick and easy
            access to Africa's favourite places to play.
          </div>
        </div>
        {/* Modal */}
        {/* TOAST NOTIFICATION WHEN A KEY IS COPIED TO THE CLIPBOARD */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.firestore.ordered.products,
    cartItems: state.cart.cartItems,
    codes: state.firestore.ordered.codes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addItem: (item) => dispatch(addItemToCart(item)),
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: "products", orderBy: ["price", "asc"] },
    { collection: "codes" },
  ])
)(HomePage);
