import React, { Component } from "react";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { createProduct, deleteProduct, editProduct, updateConversionRate, createActivationKeyProduct, deletekeyProduct, addKeyProduct, editedKeyProduct } from "../../../store/actions/cartActions";
import { selectProduct } from "../../../store/actions/authActions";

import { Redirect } from "react-router-dom";

class Admin extends Component {

  state = {
    customSwitch: 'PRODUCTS',
    search: "",
    code: "",
    postalCode: "",
    currency: "",
    keyPrice: "",
    product: "",
    passWord: "",
    userName: "",
    keyCurrency: "",
    selectedKeyProduct: "",
    productName: "",
    description: "",
    conversionRate: "",
    editedProductName: "",
    editedPrice: "",
    editedActivationFees: "",
    editedDescription: "",
    editedKeyProductName: "",
    editedKeyPrice: "",
    editedKey: "",
    editedStatus: false,
    editedPostalCode: "",
    editedUserName: "",
    editedPassword: "",
    searchField: "",
    editedKeyCurrency: "",
    editedProductCurrency: ""
    



  }
  // componentWillUnmount() {
  //   this.abortController.abort()
  // }
  // EDIT Key Modal
  handleSubmitEditKey = (e) => {
    e.preventDefault();
    if (this.state.editedKeyPrice.trim() === "" || this.state.editedKeyProductName.trim() === "") {
      return;
    }
    let updatedKeyProduct = {
      id: this.props.selectedProduct.id
    };
   
    if (this.state.editedKeyProductName) {
      updatedKeyProduct.product = this.state.editedKeyProductName;
    }
    if (this.state.editedKeyCurrency) {
      updatedKeyProduct.currency = this.state.editedKeyCurrency;
    }
    if (this.state.editedKeyPrice) {
      updatedKeyProduct.price = this.state.editedKeyPrice;
    }


    updatedKeyProduct.code = this.state.editedKey;
    updatedKeyProduct.postalCode = this.state.editedPostalCode;
    updatedKeyProduct.username = this.state.editedUserName;
    updatedKeyProduct.status = this.state.editedStatus === 'true' ? true : false;
    updatedKeyProduct.password = this.state.editedPassword; this.props.editedKeyProduct(updatedKeyProduct);
    window.$('#editKeyModal').modal('hide')


  }
  openEditKeyModal = (product) => {
    window.$('#resetModal').modal('hide');
    window.$('#registerModal').modal('hide');
    window.$('#addProductModal').modal('hide')
    window.$('#editProductModal').modal('hide')
    window.$('#editKeyModal').modal('show')

    this.props.selectProduct(product)


  }
  // Key Modal
  openKeyModal = (product) => {
    window.$('#resetModal').modal('hide');
    window.$('#registerModal').modal('hide');
    window.$('#addProductModal').modal('hide')
    window.$('#editProductModal').modal('hide')
    window.$('#activationKeysProductModal').modal('hide')
    window.$('#keyModal').modal('show')
    this.setState({
      selectedKeyProduct: product

    })
    let inputs = document.querySelectorAll('input');
    inputs.forEach(
      input => (input.value = "")
    );
    this.setState({ code: "", postalCode: "" });

  }
  handleSubmitKey = (e) => {
    e.preventDefault()

    let inputs = document.querySelectorAll('input');
    let updatedProduct = {
      ...this.state.selectedKeyProduct, codes: [...this.state.selectedKeyProduct.codes, {
        code: this.state.code,
        postalCode: this.state.postalCode,
        currency: this.state.keyCurrency,
        status: false
      }]
    }

    this.props.addKeyProduct(updatedProduct);
    inputs.forEach(
      input => (input.value = "")
    );

    this.setState({ code: "", postalCode: "" });
    window.$('#keyModal').modal('hide')
  }
  // ACTIVATION KEYS MODAL */
  openActivationKeysProductModal = () => {
    window.$('#resetModal').modal('hide');
    window.$('#registerModal').modal('hide');
    window.$('#addProductModal').modal('hide')
    window.$('#editProductModal').modal('hide')
    window.$('#activationKeysProductModal').modal('show')
    let inputs = document.querySelectorAll('input');
    inputs.forEach(
      input => (input.value = "")
    );
    this.setState({
      code: "",
      postalCode: "",
      keyPrice: "",
      product: "",
      passWord: "",
      username: "",
    });

  }

  handleSubmitActivationKeysProduct = (e) => {
    e.preventDefault()
    let inputs = document.querySelectorAll('input');



    this.props.createActivationKeyProduct({ price: this.state.keyPrice, product: this.state.product, code: this.state.code, postalCode: this.state.postalCode, password: this.state.passWord, username: this.state.userName, });
    inputs.forEach(
      input => (input.value = "")
    );

    this.setState({
      code: "",
      postalCode: "",
      keyPrice: "",
      product: "",
      passWord: "",
      username: "",
    });
    window.$('#activationKeysProductModal').modal('hide')
  }


  // CONVERSION RATE CODE
  handleSubmitConversionRate = (e) => {
    e.preventDefault()
    if (this.state.conversionRate === '') {
      return

    }

    if (this.props.rate) {

      this.props.updateConversion({
        rate: this.state.conversionRate,
        id: this.props.rate[0].id,
        author_firstName: this.props.profile.firstName,
        author_lastName: this.props.profile.lastName,

      })

    }
    let inputs = document.querySelectorAll('input');

    inputs.forEach(
      input => (input.value = "")
    );
    this.setState({ conversionRate: "" });

  }
  // ADD EDIT PRODUCT
  openEditProductModal = (product) => {
    window.$('#resetModal').modal('hide');
    window.$('#registerModal').modal('hide');
    window.$('#addProductModal').modal('hide')
    window.$('#editProductModal').modal('show')
    this.props.selectProduct(product)

  }

  componentWillReceiveProps(nextProps) {
   
    if (nextProps.selectedProduct.productName !== this.props.selectedProduct.productName || nextProps.selectedProduct.currency !== this.props.selectedProduct.currency || nextProps.selectedProduct.price !== this.props.selectedProduct.price || nextProps.selectedProduct.description !== this.props.selectedProduct.description) {
      this.setState({ editedProductName: nextProps.selectedProduct.productName, editedProductCurrency: nextProps.selectedProduct.currency, editedActivationFees: nextProps.selectedProduct.activationFees, editedPrice: nextProps.selectedProduct.price, editedDescription: nextProps.selectedProduct.description });
    }
    if (nextProps.selectedProduct.product !== this.props.selectedProduct.product || nextProps.selectedProduct.price !== this.props.selectedProduct.price || nextProps.selectedProduct.code !== this.props.selectedProduct.code || nextProps.selectedProduct.currency !== this.props.selectedProduct.currency || nextProps.selectedProduct.postalCode !== this.props.selectedProduct.postalCode || nextProps.selectedProduct.username !== this.props.selectedProduct.username || nextProps.selectedProduct.password !== this.props.selectedProduct.password || nextProps.selectedProduct.status !== this.props.selectedProduct.status) {
      this.setState({ editedKeyProductName: nextProps.selectedProduct.product, editedKeyCurrency: nextProps.selectedProduct.currency, editedKeyPrice: nextProps.selectedProduct.price, editedKey: nextProps.selectedProduct.code, editedPostalCode: nextProps.selectedProduct.postalCode, editedUserName: nextProps.selectedProduct.username, editedPassword: nextProps.selectedProduct.password, editedStatus: nextProps.selectedProduct.status });

    }


  }
  handleSubmitEditProduct = (e) => {
    e.preventDefault();
    if (this.state.editedPrice.trim() === "" || this.state.editedActivationFees.trim() === "" || this.state.editedProductName.trim() === "" || this.state.editedDescription.trim() === "") {
      return;
    }
    let updatedProduct = {
      id: this.props.selectedProduct.id
    };
    if (this.state.editedProductName) {
      updatedProduct.productName = this.state.editedProductName;
    }
    if (this.state.editedPrice) {
      updatedProduct.price = this.state.editedPrice;
    }
    if (this.state.editedProductCurrency) {
      updatedProduct.currency = this.state.editedProductCurrency;
    }
    if (this.state.editedPrice) {
      updatedProduct.activationFees = this.state.editedActivationFees;
    }
    if (this.state.editedDescription) {
      updatedProduct.description = this.state.editedDescription;
    }

    this.props.editedProduct(updatedProduct);
    window.$('#editProductModal').modal('hide')


  }
  // ADD PRODUCT CODE
  openAddProductModal = () => {
    window.$('#resetModal').modal('hide');
    window.$('#registerModal').modal('hide');
    let inputs = document.querySelectorAll('input');
    let textarea = document.querySelectorAll('textarea');
    inputs.forEach(
      input => (input.value = "")
    );
    textarea.forEach(
      textarea => (textarea.value = "")
    );

    this.setState({ price: "", productName: "", description: "" });
    window.$('#addProductModal').modal('show')
  }
  handleChangeEdit = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChangeKeyEdit = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  // Add product
  handleSubmitAddProduct = (e) => {
    e.preventDefault();
    let inputs = document.querySelectorAll('input');
    this.props.createProduct({ price: this.state.price, activationFees: this.state.activationFees, currency: this.state.currency,  productName: this.state.productName,  description: this.state.description });
    inputs.forEach(
      input => (input.value = "")
    );

    this.setState({ price: "", productName: "", description: "" });
    window.$('#addProductModal').modal('hide')
  }

  render() {
    const { products, auth, profile, users, orders } = this.props;

    let customers;
    let order;
    let sum = 0;
    if (users && orders) {
      customers = users.length - 6;
      order = orders.length
      orders.forEach(item => {
        if (item.currency === 'KES') {
          sum += Number(item.totalAmount) / 114


        } else {
          sum += Number(item.totalAmount)

        }

      })


    }





    if (!auth.uid) {
      window.$('#loginModal').modal('show');
      return <Redirect to="/" />


    }



    if (!profile.admin) return <Redirect to="/" />;




    return (
      <div className="mt-5 pb-5 mb-5">
        <div className="container mt-5 pt-5">
          <div className="row">
            <div className="text-white col-md-4 col-sm-12 mb-1">
              <div className="card w-100 text-white p-5" style={{ backgroundColor: "rgba(248, 232, 236, 0.4)" }}>
                <div className="row">
                  <div className="col">
                    <span><i className="fa fa-user"></i>Customers</span>

                  </div>
                  <div>
                    <span className="">{customers}</span>

                  </div>

                </div>




              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-1">
              <div className="card  text-white p-5" style={{ backgroundColor: "rgba(26, 176, 214, 0.4)" }}>
                <div className="row">
                  <div className="col">
                    <span><i className="fa fa-shopping-cart"></i>Orders</span>

                  </div>
                  <div>
                    <span className="">{order}</span>

                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-1">
              <div className="card text-white p-5" style={{ backgroundColor: "rgba(37, 219, 161, 0.4)" }}>
                <div className="row">
                  <div className="col">
                    <span><i className="fa fa-money"></i>Sales</span>

                  </div>
                  <div>
                    <span className="">${sum.toFixed(2)}</span>

                  </div>

                </div>

              </div>
            </div>

          </div>
          <div className="row mt-5">

            <div className="col-md-4  col-sm-12">
              <div className="mb-4 pb-5">
                <span className="badge badge-success p-2">current rate 1$ = kes{this.props.rate && this.props.rate[0].rate}</span>
              </div>

              <span className="list-group-item bg-dark border-0 active pb-4">
                Products
                <span className="btn float-right bg-danger add-product" onClick={this.openAddProductModal} style={{ zIndex: '-1px' }}><i className="material-icons text-dark">&#xE147;</i> <span>Add Product</span></span>
                <br></br>
              </span>
              <form onSubmit={this.handleSubmitConversionRate} className="mt-2">

                <div className="form-group">
                  <input type="number" min="0" className="form-control" id="conversionRate" onChange={this.handleChange} placeholder="Conversion rate...$ = kes" required="required" />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-lg bg-secondary text-white btn-block">Save</button>
                </div>
              </form>

              <div className="mt-2">
                <span className="list-group-item bg-dark border-0 active pb-4">
                  Product Key
                 <span className="btn float-right bg-danger add-product " onClick={this.openActivationKeysProductModal}><i className="material-icons text-dark">&#xE147;</i> <span>Add Product</span></span>
                  <br></br>
                </span>
              </div>
              <br></br>

              <div className="form-group w-50 bg-transparent mt-3">
                <label htmlFor="customSwitch" className="text-white">Choose what you want to display:</label>

                <select className="form-control  text-black " id="customSwitch"
                  onChange={this.handleChange}
                >
                  <option value="PRODUCTS" disabled>
                    Choose what you want to display
                </option>
                  <option value="PRODUCTS">PRODUCTS LIST</option>
                  <option value="KEYS">ACTIVATION KEYS</option>
                </select>
              </div>



            </div>

            {/* Start of product section  */}
            <div className="col-md-8 col-sm-12">

              {this.state.customSwitch.toLowerCase() === 'products' ? <input className="form-control mr-sm-2" id="search" type="search" onChange={this.handleChange} placeholder="Search" aria-label="Search" /> : <input className="form-control w-100 mr-sm-2" id="searchField" type="search" onChange={this.handleChange} placeholder="Search" aria-label="Search" />}
              {
                this.state.customSwitch.toLowerCase() === 'products' ? <div>
                  <h5 className="text-light mt-3">Products</h5>
                  <div className="card p-5 shadow-0 bg-transparent  border-0" style={{ minHeight: '400px', maxHeight: '400px', overflow: 'auto' }}>

                    <div className="row">
                      {
                        products && products.length > 0 ? products.filter(p => p.productName.toLowerCase().includes(this.state.search.toLocaleLowerCase()) || p.price.toLowerCase().includes(this.state.search.toLocaleLowerCase())).map((product) => (
                          <div className="col-md-4 col-sm-12 mb-1 mt-1" key={product.id}>
                            <div className="card">
                              <img src={product.productName.toLowerCase() === 'spotify' ? '/spotify-bg.jpg' : '/psn-bg.jpg'} style={{ height: '130px' }} className="card-img-top" alt="..." />
                              <div className="card-body border-0">
                                <span className="text-muted">{product.productName}</span>
                                <br></br>
                                <span className="text-muted" style={{ fontSize: '10px' }}>{product.description}</span>

                              </div>
                              <div className="card-footer border-0">
                                <span>{product.currency}{product.price}</span>
                                <i className="material-icons float-right mr-2" onClick={() => {
                                  this.props.deleteItem(product);
                                }} style={{ cursor: "pointer" }}>&#xE5C9;</i>
                                <i className="fa fa-pencil float-right ml-4" onClick={() => this.openEditProductModal(product)} style={{ cursor: "pointer" }}></i>

                              </div>


                            </div>

                          </div>

                        )) : null}


                    </div>

                  </div>
                </div> : <div>
                    <h5 className="text-light mt-3">Activation keys</h5>
                    <div className="card p-5 shadow-0 bg-transparent  border-0" style={{ minHeight: '400px', maxHeight: '400px', overflow: 'auto' }}>

                      <div className="row">
                        <div className="col-12">

                          {
                            this.props.codes && this.props.codes.length > 0 ? this.props.codes.filter(p => p.product.toLowerCase().includes(this.state.searchField.toLowerCase())).map((product) => (
                              <span className="list-group-item " key={product.id} >
                                <span>{product.product}</span>
                                <span className="ml-5">{product.currency}{product.price}</span>
                                <br />
                                {product.code || product.postalCode ? <span className={!product.status ? 'badge badge-success text-center p-2' : 'badge badge-danger text-center'}>
                                  Key: {product.code && product.code}
                                  <br></br>
                                  <br></br>
                    Postal: {product.postalCode && product.postalCode}


                                </span> : null}
                                <br></br>
                                {
                                  product.username || product.password ? <span className={!product.status ? 'badge badge-success text-center p-2 mt-2' : 'badge badge-danger mt-2 text-center'}>
                                    UserName: {product.username && product.username}
                                    <br></br>
                       Password: {product.password && product.password}


                                  </span> : null
                                }


                                <span className="float-right">

                                  <span className="ml-5" onClick={() => this.openEditKeyModal(product)}>  <span href={void (0)} className="settings" title="Edit key" data-toggle="tooltip"><i className="material-icons">&#xE254;</i></span></span>

                                  <span className="ml-5" onClick={() => {
                                    this.props.deletekeyProduct(product);
                                  }}> <span className="delete" title="Delete" data-toggle="tooltip"><i className="material-icons">&#xE5C9;</i></span></span>

                                </span>
                                <br></br>
                                <br></br>
                                <br></br>

                              </span>
                            )) : null
                          }


                        </div>




                      </div>

                    </div>
                  </div>
              }

              {/* End of products holder */}

              {/* End Activation keys */}


            </div>

          </div>
          {/* End of product section */}
          {/* EDIT ACTIVATION KEY/Usename MODAL*/}
          <div id="editKeyModal" className="modal fade" data-backdrop="static">
            <div className="modal-dialog modal-login">
              <div className="modal-content">
                <div className="modal-header">


                  <h4 className="modal-title">Edit Activation Key/Username</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                </div>
                <div className="modal-body">
                  <form onSubmit={this.handleSubmitEditKey}>



                    <div className="form-group">
                      <label htmlFor="ProductName" style={{ fontSize: "14px" }}>Product Name:</label>
                      <input type="text" className="form-control" id="editedKeyProductName" value={this.state.editedKeyProductName || ""} name="editedKeyProductName" onChange={this.handleChangeKeyEdit} placeholder="Product name..." required="required" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="price" style={{ fontSize: "14px" }}>Price:</label>
                      <input type="number" min="0" step="0.01" className="form-control" id="editedKeyPrice" value={this.state.editedKeyPrice} name="editedKeyPrice" onChange={this.handleChangeKeyEdit} placeholder="Product price..." required="required" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="ProductName" style={{ fontSize: "14px" }}>Activation Key:</label>
                      <input type="text" className="form-control" id="editedKey" value={this.state.editedKey || ""} name="editedKey" onChange={this.handleChangeKeyEdit} placeholder="Activation key..." />
                    </div>
                    <div className="form-group">
                      <label htmlFor="ProductName" style={{ fontSize: "14px" }}>Postal code:</label>
                      <input type="text" className="form-control" id="editedPostalCode" value={this.state.editedPostalCode || ""} name="editedPostalCode" onChange={this.handleChangeKeyEdit} placeholder="Postal code..." />
                    </div>
                    <div className="form-group">
                      <label htmlFor="ProductName" style={{ fontSize: "14px" }}>Currency:</label>
                      <input type="text" className="form-control" id="editedKeyCurrency" value={this.state.editedKeyCurrency || ""} name="editedKeyCurrency" onChange={this.handleChangeKeyEdit} placeholder="Currency" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="ProductName" style={{ fontSize: "14px" }}>UserName:</label>
                      <input type="text" className="form-control" id="editedUserName" value={this.state.editedUserName || ""} name="editedUserName" onChange={this.handleChangeKeyEdit} placeholder="UserName..." />
                    </div>
                    <div className="form-group">
                      <label htmlFor="ProductName" style={{ fontSize: "14px" }}>Password:</label>
                      <input type="text" className="form-control" id="editedPassword" value={this.state.editedPassword || ""} name="editedPassword" onChange={this.handleChangeKeyEdit} placeholder="Password..." />
                    </div>
                    <div className="form-group">
                      <label htmlFor="status" style={{ fontSize: "14px" }}>Status:</label>
                      <input type="text" className="form-control" id="editedStatus" value={this.state.editedStatus || ""} name="editedStatus" onChange={this.handleChangeKeyEdit} placeholder="Status..." />
                    </div>

                    <div className="form-group">
                      <button type="submit" className="btn btn-lg btn-block">Edit</button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
          {/* End of Edit Key ACTIVATION Modal */}


          {/* ADD ACTIVATION KEYS MODAL */}
          <div id="activationKeysProductModal" className="modal fade" data-backdrop="static">
            <div className="modal-dialog modal-login">
              <div className="modal-content">
                <div className="modal-header">

                  <h4 className="modal-title">Add activation key/Username</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                </div>
                <div className="modal-body">
                  <form onSubmit={this.handleSubmitActivationKeysProduct}>

                    <div className="form-group">
                      <input type="text" className="form-control" id="product" onChange={this.handleChange} placeholder="Product..." required="required" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" id="keyCurrency" onChange={this.handleChange} placeholder="Currency" required="required" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" id="code" onChange={this.handleChange} placeholder="Activation key..." />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" id="userName" onChange={this.handleChange} placeholder="UserName..." />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" id="postalCode" onChange={this.handleChange} placeholder="Postal code..." />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" id="passWord" onChange={this.handleChange} placeholder="Password..." />
                    </div>



                    <div className="form-group">
                      <input type="number" min="0" step="0.01" className="form-control" id="keyPrice" onChange={this.handleChange} placeholder="Price..." required="required" />
                    </div>






                    <div className="form-group">
                      <button type="submit" className="btn btn-lg btn-block">Add</button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
          {/* END OF KEYS MODAL */}
          {/* End of analytics */}
          {/* End of add products section */}

        </div>
        {/* EDIT PRODUCT */}
        <div id="editProductModal" className="modal fade" data-backdrop="static">
          <div className="modal-dialog modal-login">
            <div className="modal-content">
              <div className="modal-header">


                <h4 className="modal-title">Edit Product</h4>
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div className="modal-body">
                <form onSubmit={this.handleSubmitEditProduct}>

                  <div className="form-group">
                    <label htmlFor="ProductName" style={{ fontSize: "14px" }}>Product Name:</label>
                    <input type="text" className="form-control" id="editedProductName" value={this.state.editedProductName || ""} name="editedProductName" onChange={this.handleChangeEdit} placeholder="Product name..." required="required" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="editedProductCurrency" style={{ fontSize: "14px" }}>Product Currency:</label>
                    <input type="text" className="form-control" id="editedProductCurrency" value={this.state.editedProductCurrency || ""} name="editedProductCurrency" onChange={this.handleChangeEdit} placeholder="Currency" required="required" />
                  </div>

                  <div className="form-group">
                    <label htmlFor="price" style={{ fontSize: "14px" }}>Price:</label>
                    <input type="number" min="0" className="form-control" id="price" step="0.01" value={this.state.editedPrice} name="editedPrice" onChange={this.handleChangeEdit} placeholder="Product price..." required="required" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="editedActivationFees" style={{ fontSize: "14px" }}>Activation fees:</label>
                    <input type="number" min="0" className="form-control" step="0.01" id="editedActivationFees" value={this.state.editedActivationFees || ""} name="editedActivationFees" onChange={this.handleChangeEdit} placeholder="Activation fees..." />
                  </div>

                  <div className="form-group">
                    <label htmlFor="description" style={{ fontSize: "14px" }}>Description:</label>
                    <textarea id="description" className="form-control" value={this.state.editedDescription} name="editedDescription" onChange={this.handleChangeEdit} placeholder="Product description..." required="required"></textarea>

                  </div>




                  <div className="form-group">
                    <button type="submit" className="btn btn-lg btn-block">Edit</button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
        {/* END OF PRODUCT MODAL */}



        {/* ADD PRODUCT MODAL */}
        <div id="addProductModal" className="modal fade" data-backdrop="static">
          <div className="modal-dialog modal-login">
            <div className="modal-content">
              <div className="modal-header">

                <h4 className="modal-title">Add Product</h4>
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div className="modal-body">
                <form onSubmit={this.handleSubmitAddProduct}>

                  <div className="form-group">
                    <input type="text" className="form-control" id="productName" onChange={this.handleChange} placeholder="Product name..." required="required" />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" id="currency" onChange={this.handleChange} placeholder="Currency" required="required" />
                  </div>

                  <div className="form-group">
                    <input type="number" min="0" className="form-control" id="price" step="0.01" onChange={this.handleChange} placeholder="Product price..." required="required" />
                  </div>
                  <div className="form-group">
                    <input type="number" min="0" className="form-control" id="activationFees" step="0.01" onChange={this.handleChange} placeholder="Activation fees..." required="required" />
                  </div>

                  <div className="form-group">
                    <textarea id="description" className="form-control" onChange={this.handleChange} placeholder="Product description..." required="required"></textarea>

                  </div>




                  <div className="form-group">
                    <button type="submit" className="btn btn-lg btn-block">Add</button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
        {/* END OF ADD PRODUCT MODAL */}
      </div>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    rate: state.firestore.ordered.conversion,
    products: state.firestore.ordered.products,
    users: state.firestore.ordered.users,
    orders: state.firestore.ordered.orders,
    codes: state.firestore.ordered.codes,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    selectedProduct: state.auth.selectedProduct,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createProduct: (product) => dispatch(createProduct(product)),
    addKeyProduct: (product) => dispatch(addKeyProduct(product)),
    deleteItem: (product) => dispatch(deleteProduct(product)),
    deletekeyProduct: (product) => dispatch(deletekeyProduct(product)),
    selectProduct: (product) => dispatch(selectProduct(product)),
    editedProduct: (product) => dispatch(editProduct(product)),
    editedKeyProduct: (product) => dispatch(editedKeyProduct(product)),
    updateConversion: (rate) => dispatch(updateConversionRate(rate)),
    createActivationKeyProduct: (product) => dispatch(createActivationKeyProduct(product))
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: "products" },
    { collection: "orders" },
    { collection: "users" },
    { collection: 'conversion' },
    { collection: 'codes' }
  ])
)(Admin);
