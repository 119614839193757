import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { selectProduct } from "../../../store/actions/authActions";
import { compose } from "redux";
import moment from "moment";


class OrderDetails extends Component {
  constructor() {
    super();
    this.state = {
      highlight: false,

    };
  }

  getActivationKeys = (item) => {
    window.$('#activationKeysModal').modal('show');


    this.props.selectedProduct(item)

  }
  copyToClipboard = (key) => {

    navigator.clipboard.writeText(`${key}`);

    window.$('.toast').toast('show')
    setTimeout(() => {
      window.$('.toast').toast('hide')

    }, 1000)


  }

  render() {
    const { auth, orders, modalProduct } = this.props;

    const id = this.props.match.params.id;
    let product;
    if (orders && auth) {
      orders.forEach((order) => {
        if (order.id === id) {
          product = order;
        }
      });
    }
    if (!auth.uid) {
      window.$('#loginModal').modal('show');

    }
    if (!auth.uid) return <Redirect to="/" />;
    return (
      <div className="container  mt-5 mb-5">
        <div className="d-flex justify-content-center row">
          <div className="col-md-10">
            <hr />
            <div className="receipt text-white p-3 rounded"><img src="/utally-l.png" width="120" alt="" />
              <h4 className="mt-2 mb-3 text-success">Your order details</h4>
              <hr />

              <div className="d-flex flex-row justify-content-between align-items-center order-details">
                <div><span className="d-block fs-12">Order date</span><span className="font-weight-bold text-break" style={{ fontSize: "13px" }}>{product
                  ? moment(new Date(product.createdAt.seconds * 1000)).format(
                    "DD MMM YYYY hh:mm a"
                  )
                  : null}</span></div>
                <div><span className="d-block fs-12">Order number</span><span className="text-break" style={{ fontSize: "13px" }}>{id}</span></div>
                <div className="text-break"><span className="d-block fs-12 text-break">Payment method</span><span style={{ fontSize: "13px" }}>{product && product.means === "rave"
                  ? "Mpesa/Card"
                  : "Paypal"}</span></div>

              </div>
              <hr />
              {product &&
                product.items.map((item) => (
                  <div className="d-flex justify-content-between align-items-center product-details" key={item.id}>
                    <div className="d-flex flex-row product-name-image"><img className="rounded-circle" src={item.productName.toLowerCase() === "spotify" ? "/spotify-bg.jpg" : "/psn-bg.jpg"} width="80" height="80" alt="" />
                      <div className="d-flex flex-column justify-content-between ml-2">
                        <div><span className="d-block font-weight-bold p-name">{item.description}</span><span className="fs-12">{item.productName}</span></div><span className="fs-12">Activation keys/Username: <svg width="2em" style={{ cursor: "pointer" }} onClick={() => this.getActivationKeys(item)} height="2em" viewBox="0 0 16 16" className="bi bi-eye" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
                          <path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg></span><span className="fs-12">Qty: {item.quantity}</span>
                      </div>
                    </div>

                    <div className="product-price">
                      <h5>{item.currency}{item.price * item.quantity}</h5>
                    </div>
                  </div>

                ))}
              <br />



              <div className="mt-5 amount row">
                <div className="d-flex justify-content-center col-md-6"></div>
                <div className="col-md-6">
                  <div className="billing">
                    <div className="d-flex justify-content-between"><span>Subtotal</span><span className="font-weight-bold">{product && product.currency === "USD" ? "$" : "kes"}
                      {product && product.currency === "USD"
                        ? product.subTotal
                        : null}
                      {product && product.currency === "KES"
                        ? parseInt(product.subTotal).toFixed(2)
                        : null}</span></div>
                    <div className="d-flex justify-content-between mt-2"><span>Processing fees</span><span className="font-weight-bold">{product && product.currency === "USD" ? "$" : "kes"}
                      {product && product.processingFees ? product.processingFees : null}</span></div>

                    <div className="d-flex justify-content-between mt-2"><span>Tax</span><span className="font-weight-bold">{product && product.currency === "USD" ? "$" : "kes"}
                      {product && product.salesTax ? product.salesTax : null}</span></div>

                    <hr></hr>
                    <div className="d-flex justify-content-between mt-1"><span className="font-weight-bold">Total</span><span className="font-weight-bold text-success"> {product && product.currency === "USD" ? "$" : "kes"}
                      {product && product.totalAmount
                        ? product.totalAmount
                        : null}</span></div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="d-flex justify-content-between align-items-center footer">
                <div className="thanks"><span className="d-block font-weight-bold">Thanks for shopping</span><span>U-tally team</span></div>
                <div className="d-flex flex-column justify-content-end align-items-end"><span className="d-block font-weight-bold">Need Help?</span><span>Call - +254740299030</span></div>
              </div>

            </div>
          </div>
        </div>
        {/* ACTIVATION KEYS MODAL */}

        <div className="modal fade" id="activationKeysModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body ">
                <div className="text-right"><svg width="1em" data-dismiss="modal" height="1em" viewBox="0 0 16 16" className="bi bi-x close" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg> </div>
                <div className="px-4 py-5">
                  <h5 className="text-uppercase">Your activation keys/Username</h5>

                  <div className="mb-3">
                    <hr className="new1" />
                  </div>
                  <div className="d-flex justify-content-between"> <span className="font-weight-bold">{modalProduct.productName}(Qty:{modalProduct.quantity})</span> <span className="text-muted">Copy</span> </div>
                  <div className="d-flex justify-content-between"> <small>{modalProduct.description}</small> <small></small> </div>
                  {modalProduct && modalProduct.codes
                    ? modalProduct.codes.map((code) => (
                      <div key={code.code} className="d-flex mb-3 mt-2 justify-content-between"> <small>Key/Username: <strong>{code.code}{code.username}</strong> <br /> <small>Postal code/Password <strong>{code.postalCode}{code.password}</strong></small></small> <small><svg style={{ cursor: "pointer" }} onClick={() => this.copyToClipboard(code.code)} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-bookmarks" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z" />
                        <path fillRule="evenodd" d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z" />
                      </svg></small>  </div>



                    )) : null}
                  {/* TOAST NOTIFICATION WHEN A KEY IS COPIED TO THE CLIPBOARD */}

                  <div role="alert" aria-live="assertive" aria-atomic="true" className="toast" data-autohide="false">
                    <div className="toast-header">

                      <strong className="mr-auto"></strong>

                      <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="toast-body">
                      copied to clipboard!
  </div>
                  </div>
















                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    orders: state.firestore.ordered.orders,
    modalProduct: state.auth.selectedProduct,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    selectedProduct: (product) => dispatch(selectProduct(product)),
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "orders",
    },
  ])
)(OrderDetails);
