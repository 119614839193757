import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/navbar/Navbar";
import HomePage from "./components/HomePage";
import Faqs from "./components/faqs/Faqs";
import Admin from "./components/auth/admin/Admin";
import Profile from "./components/auth/profile/Profile";
import OrderDetails from "./components/auth/profile/OrderDetails";
import Checkout from "./components/auth/checkout/Checkout";
import Thanks from "./components/auth/checkout/Thanks";
import Footer from "./components/layout/footer/Footer";
import PageNotFound from "./PageNotFound";


class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App" onClick={this.outsideClick}>
          <header>
            <Navbar />
          </header>
          <main style={{minWidth: '80%'}}>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/faqs" component={Faqs} />
              <Route exact path="/checkout" component={Checkout} />
              <Route exact path="/thanks" component={Thanks} />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/order/:id" component={OrderDetails} />
              <Route exact component={PageNotFound} />
            </Switch>
            {/* <div className="icon-bar">

              <a href="https://www.facebook.com/utallyeastafrica" target="_blank" rel="noopener noreferrer" className="facebook"><i className="fa fa-facebook"></i></a>
              <a href="https://twitter.com/utally_ea" className="twitter" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter" ></i></a>
              <a href="https://instagram.com/utally_ea" className="instagram" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
              <a href="mailto:support@u-tally.com" className="mail" target="_blank" rel="noopener noreferrer"><i className="fa fa-envelope"  ></i></a>
            </div> */}
          </main>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}



export default App;
