export const toggleHidden = () => {
  return (dispatch, getState) => {
    // make async call to the database
    dispatch({ type: "TOGGLE_HIDDEN" });
  };
};

export const clearCart = () => {
  return {
    type: "CLEAR_CART",
  };
};

export const addItemToCart = (product) => {
  return (dispatch, getState) => {
    dispatch({ type: "ADD_ITEM_TO_CART", product });
  };
};

export const removeItem = (product) => {
  return (dispatch, getState) => {
    // make async call to the database
    dispatch({ type: "REMOVE_ITEM", product });
  };
};
export const decrementItem = (product) => {
  return (dispatch, getState) => {
    // make async call to the database
    dispatch({ type: "DECREMENT_ITEM_QUANTITY", product });
  };
};

export const createProduct = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make a async call to the database
    const firestore = getFirestore();
    firestore
      .collection("products")
      .add({
        ...product,
        codes: [],
        createdAt: new Date(),
      })
      .then(() => {
        dispatch({ type: "CREATE_PRODUCT", product });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_PRODUCT_ERROR", error });
      });
  };
};
export const editedKeyProduct = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make a async call to the database
    const firestore = getFirestore();
    firestore
      .collection("codes")
      .doc(`${product.id}`)
      .update({
        ...product,
      })
      .then(() => {
        dispatch({ type: "EDIT_KEY_PRODUCT", product });
      })
      .catch((error) => {
        dispatch({ type: "EDIT_KEY_PRODUCT_ERROR", error });
      });
  };
};


export const createActivationKeyProduct = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make a async call to the database
    const firestore = getFirestore();
    firestore
      .collection("codes")
      .add({
        ...product,
        status: false,

        createdAt: new Date(),
      })
      .then(() => {
        dispatch({ type: "CREATE_KEYS_PRODUCT", product });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_KEYS_PRODUCT_ERROR", error });
      });
  };
};


export const deletekeyProduct = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make a async call to the database
    const firestore = getFirestore();
    firestore
      .collection("codes")
      .doc(`${product.id}`)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_KEY_PRODUCT", product });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_KEY_PRODUCT_ERROR", error });
      });
  };
};

export const addKeyProduct = (product) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();
    firestore
      .collection("codes")
      .doc(`${product.id}`)
      .update({
        codes: product.codes

      })
      .then(() => {
        dispatch({ type: "UPDATE_KEYS" });
      })
      .catch((error) => {
        // dispatch({ type: "EDIT_PRODUCT_ERROR", error });
      });
  }
}

export const update_counter = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();

    // get current user ID
    const id = getState().firebase.auth.uid;

    firestore
      .collection("counter")
      .doc(`${id}`)
      .set({
        counts: new Date(),
      })
      .then(() => {
        dispatch({ type: "UPDATE_COUNTER" });
        return
      })
      .catch((error) => {
        // dispatch({ type: "EDIT_PRODUCT_ERROR", error });
        console.log(error)
      });


  };
};

export const updateConversionRate = (rate) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database  

    // dispatch({ type: "UPDATE_COUNTER" });

    const firestore = getFirestore();
    firestore
      .collection("conversion")
      .doc(`${rate.id}`)
      .set({
        rate: rate.rate,
        author_firstName: rate.author_firstName,
        author_lastName: rate.author_lastName,
      })
      .then(() => {
        dispatch({ type: "UPDATE_CONVERSSION_RATE" });
      })
      .catch((error) => {
        // dispatch({ type: "EDIT_PRODUCT_ERROR", error });
      });
  }
}


export const editProduct = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make a async call to the database
    const firestore = getFirestore();
    firestore
      .collection("products")
      .doc(`${product.id}`)
      .update({
        ...product,
      })
      .then(() => {
        dispatch({ type: "EDIT_PRODUCT", product });
      })
      .catch((error) => {
        dispatch({ type: "EDIT_PRODUCT_ERROR", error });
      });
  };
};

export const deleteProduct = (product) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make a async call to the database
    const firestore = getFirestore();
    firestore
      .collection("products")
      .doc(`${product.id}`)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_PRODUCT", product });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_PRODUCT_ERROR", error });
      });
  };
};

export const saveOrder = (order) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    // make a async call to the database

    const customer_profile = getState().firebase.profile;
    const customer_id = getState().firebase.auth.uid;

    firestore
      .collection("orders")
      .add({
        ...order,
        customer_id: customer_id,
        customer_firstName: customer_profile.firstName,
        customer_lastName: customer_profile.lastName,
        customer_email: customer_profile.email,
      })
      .then((response) => {
        dispatch({ type: "SAVE_ORDER", order });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_PRODUCT_ERROR", error });
      });
  };
};
