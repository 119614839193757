export const clearError = () => (
  {
    type: "CLEAR_ERROR",
  }
)
export const login = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    // make api request to sign
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((result) => {
        dispatch({ type: "LOGIN_SUCCESS", result });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};
export const toggleNotifications = (payload) => {
  return {
    type: "TOGGLE_NOTIFICATIONS",
    payload: payload
  };
};

export const loginWithGoogle = () => {
  return (dispatch, getState, { getFirebase }) => {
    // make api request to sign
    const firebase = getFirebase();
    //google provider object
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    //login with google popup window
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        dispatch({ type: "LOGIN_WITH_GOOGLE_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_WITH_GOOGLE_ERROR", err });
      });
  };
};

export const logout = () => {
  return (dispatch, getState, { getFirebase }) => {
    // Make api request
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      });
  };
};
export const selectProduct = (product) => {
  return {
    type: "SELECT_PRODUCT",
    product: product,
  };
};
export const clearAuthErr = () => {
  return {
    type: "CLEAR_AUTH_ERR",

  };
};

export const register = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((response) => {
        firestore
          .collection("users")
          .doc(response.user.uid)
          .set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            email: newUser.email,
            admin: false,
            initials:
              newUser.firstName[0].toUpperCase() +
              newUser.lastName[0].toUpperCase(),
          });
        return



      }).then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};


export const update_counter_admin = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    // get current user ID
    const id = getState().firebase.auth.uid;


    firestore
      .collection("counteradmin")
      .doc(`${id}`)
      .set({
        counts: new Date(),
      })
      .then(() => {
        dispatch({ type: "UPDATE_COUNTER_ADMIN" });

      })
      .catch((error) => {
        // dispatch({ type: "EDIT_PRODUCT_ERROR", error });
        console.log(error)
      });


  };
};












export const resetPassword = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {

        dispatch({ type: "PASSWORD_RESET_SUCCESS" });

      })
      .catch((err) => {
        dispatch({ type: "PASSWORD_RESET_ERROR", err });

      });
  };
};
