const initialState = {
  authError: null,
  notificationsToggle: false,
  selectedProduct: {},
  loginResult: null
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {

    case "LOGIN_SUCCESS":
      return {
        ...state,
        authError: null,
        loginResult: action.result
      };
    case "CLEAR_AUTH_ERR":
      return {
        ...state,
        authError: null,
      };
    case "CLEAR_ERROR":
      return {
        ...state,
        authError: null
      }
    case "TOGGLE_NOTIFICATIONS":
      return {
        ...state,
        notificationsToggle: action.payload,
      };
    case "SELECT_PRODUCT":
      return {
        ...state,
        selectedProduct: action.product,
      };

    case "LOGIN_ERROR":
      return {
        ...state,
        authError: action.err,
      };
    case "LOGIN_WITH_GOOGLE_SUCCESS":
      return {
        ...state,
        authError: null,
      };
    case "LOGIN_WITH_GOOGLE_ERROR":
      return {
        ...state,
        authError: action.err,
      };
    case "LOGOUT_SUCCESS":
      return state;
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        authError: null,
      };
    case "UPDATE_COUNTER_ADMIN":
      return state;
    case "SIGNUP_ERROR":
      return {
        ...state,
        authError: action.err.message,
      };
    case "PASSWORD_RESET_SUCCESS":
      return {
        ...state,
        authError: null,
      };
    case "PASSWORD_RESET_ERROR":
      return {
        ...state,
        authError: action.err.message,
      };

    default:
      return state;
  }
};

export default authReducer;
