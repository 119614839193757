import React from 'react'

function PageNotFound() {
    return (
        <div>
            <div className="container">
                <div className="page-not-found-main">
                    <span role="img" aria-labelledby="">&#128532;</span>
                    <h3>Ouups, page not found</h3>
                    <p>We are sorry for the inconvenience. It looks like you're trying to</p>
                    <p>access a page that has been deleted or never even existed.</p>
                    <a href="/" type="button" className="btn btn-outline-success">BACK TO HOMEPAGE</a>

                </div>



            </div>

        </div>
    )
}

export default PageNotFound;
