import { filteredCartItems, removeItem, decrementItem } from "./cartUtils";

const initialState = {
  hidden: false,
  cartItems: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ITEM_TO_CART":
      return {
        ...state,
        cartItems: filteredCartItems(state.cartItems, action.product),
      };
    case "TOGGLE_HIDDEN":
      return {
        ...state,
        hidden: !state.hidden,
      };

    case "CREATE_PRODUCT":
      return state;

    case "CREATE_KEYS_PRODUCT":
      return state;

    case "CREATE_KEYS_PRODUCT_ERROR":
      return state;

    case "UPDATE_COUNTER":
      return state;


    case "UPDATE_CONVERSSION_RATE":
      return state;

    case "CREATE_PRODUCT_ERROR":
      return state;
    case "UPDATE_KEYS":
      return state;

    case "DELETE_PRODUCT":
      return state;
    case "DELETE_KEY_PRODUCT":
      return state;

    case "DELETE_KEY_PRODUCT_ERROR":
      return state;

    case "REMOVE_ITEM":
      return {
        ...state,
        cartItems: removeItem(state.cartItems, action.product),
      };
    case "CLEAR_CART":
      return {
        ...state,
        cartItems: [],
      };
    case "EDIT_PRODUCT":
      return {
        ...state,
      };
    case "EDIT_KEY_PRODUCT":
      return {
        ...state,
      };

    case "DECREMENT_ITEM_QUANTITY":
      return {
        ...state,
        cartItems: decrementItem(state.cartItems, action.product),
      };
    case "SAVE_ORDER":
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default cartReducer;
