import React from "react";

class Faqs extends React.Component {

  render() {
    return (
      <div className="container">

        <div className="row mt-5">


          <div className="justify-content-center border-0 p-5">

            <div className="text-center pb-5">
              <h1 className="mt-5 font-bold" ><span className="text-light">Frequently Asked</span> <span className="text-info">Questions</span></h1>
            </div>
            <div className="">
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> How to redeem the cards?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>PSN - load the funds into your PSN wallet via browser or Playstation Store on (console) and scroll down to redeem codes. <br /> Spotify - follow the link: <a target="_blank" rel="noopener noreferrer" href="https://www.spotify.com/us/redeem/">www.spotify.com/us/redeem/</a> and enter the activation code and postal code for Spotify.</p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> How to install Spotify?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}> Android - <a rel="noopener noreferrer" target="_blank" href="https://love-music.gitbook.io/music/how-to-install-spotify-in-any-country-for-android">https://love-music.gitbook.io/music/how-to-install-spotify-in-any-country-for-android</a></p>
              <p className="text-light" style={{ lineHeight: 2 }}>iOS - <a rel="noopener noreferrer" target="_blank" href="https://love-music.gitbook.io/music/how-to-install-spotify-in-any-country-for-iphone-or-ipad " >https://love-music.gitbook.io/music/how-to-install-spotify-in-any-country-for-iphone-or-ipad</a> <br /> Mac and Windows - <a rel="noopener noreferrer" target="_blank" href="https://love-music.gitbook.io/music/how-to-install-spotify-in-any-country-for-mac-and-windows"> https://love-music.gitbook.io/music/how-to-install-spotify-in-any-country-for-mac-and-windows</a></p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> Refunds?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>Once receiving your order there are absolutely no refunds. The delivered product is guaranteed to be exactly as described and in working condition, if your delivered product is not as described or not in working condition, contact us within 12 hours of delivery.</p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> How long does it take to receive my Spotify Account?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>There could be a delay if your order is under management review, a small percentage of orders require approval by management. The majority of these orders are delivered within 10 minutes of payment, we offer  a guaranteed 30 minute delivery on orders if it has passed the verification in place.</p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> Will I need a VPN for Spotify?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>No you will not need a VPN globally, because you are on a paid premium subscription.</p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> Spotify Account Creation?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>For users without a Spotify Account, select the 'Account Creation Option' in the Spotify section along with your choice of monthly gift card. Once you make successful payment, you will receive an email with a brand new username and password for Spotify, along with links to download the app for your phone and computer. Our Cards can only be used on US accounts.</p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> Code Delivery?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>We email the activation codes on payment confirmation. The same is also available in your profile account.</p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> Payment Methods?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>We accept MPESA, Visa, Mastercard or Paypal.</p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> Countries we deliver to?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>Kenya, Uganda, Tanzania, Ethiopia, Rwanda and Burundi</p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> Card Information Security?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>We do not store your card details - payment will be processed through Visa, Mastercard, Flutterwave and Paypal. They are PA DSS & PCI DSS Compliant.</p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> Why do you use US Region Cards?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>They are the most economical and offer a wide range of services.</p>

              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> What if I have a different PSN Region account?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>No problem. Just create a separate US Region PSN account to purchase games (cheaper) with. You can then use your old/main account to play the games despite the region. </p>
              <h6 className="mb-3 text-info" style={{ fontWeight: 600, fontSize: '1.1rem' }}><i className="fa fa-question-circle text-center"></i> How does Spotify work?</h6>
              <p className="text-light" style={{ lineHeight: 2 }}>If you need an account select the Account Creation option. This will allow you to access Spotify Free Version. You can thereafter redeem the Gift Cards for Spotify Premium.</p>













            </div>

          </div>


        </div>







      </div>

    );
  }
}

export default Faqs;
