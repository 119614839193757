import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";






// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyA-TKcEQXCTU6UVbvaf30QmYSEUu5xfU2o",
  authDomain: "utally-d9881.firebaseapp.com",
  databaseURL: "https://utally-d9881.firebaseio.com",
  projectId: "utally-d9881",
  storageBucket: "utally-d9881.appspot.com",
  messagingSenderId: "167047287999",
  appId: "1:167047287999:web:1f92f955b7de7dad81e20a",
  measurementId: "G-1KXE85X39L"


};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
