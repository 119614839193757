import authReducer from "./authReducer";
import cartReducer from "./cartReducer";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cart"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});

export default persistReducer(persistConfig, rootReducer);
