import React, { Component } from "react";
import { connect } from "react-redux";
// import Rave from 'react-flutterwave-rave'
import { PayPalButton } from "react-paypal-button-v2";
import {
  saveOrder,
  clearCart,
  removeItem,
  addItemToCart,
  decrementItem,
} from "../../../store/actions/cartActions";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import firebase from "../../../firebase/firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IntaSend from "intasend-inlinejs-sdk";

class Checkout extends Component {
  constructor() {
    super();
    this.state = {
      logo: "",
      currency: "KES",
      // live
      key: "FLWPUBK-10424ae1ca048aa98aca4f92a0e0a1dc-X", // RavePay PUBLIC KEY
      // testing
      // key: "FLWPUBK-3f1bc4cf06e32c904a8ec52f63798a9f-X", // RavePay PUBLIC KEY
    };
  }

  // Rave/Flutterwave callback

  onMpesaPay = (amount) => {
    const { currency } = this.state;
    const { email, firstName, lastName } = this.props.profile;
    const PUBLIC_KEY = "ISPubKey_live_a98c8e75-024d-4d34-b2c0-d0c6e5237caa";
    // const PUBLIC_KEY = 'ISPubKey_test_6a7b6edf-41bb-4b19-b5d0-9892d51fff40'
    let instance = new IntaSend({
      publicAPIKey: PUBLIC_KEY,
      live: true, // Set to true when going live
    });
    instance
      .run({
        amount,
        currency,
        email,
        first_name: firstName,
        last_name: lastName,
      })
      .on("COMPLETE", (response) => {
        if (response.state === "COMPLETE") {
          instance.exitPay();
          this.props.saveOrder(this.raveReceipt());
          this.props.clearCart();
          this.props.history.push("/thanks");
        }
      })
      .on("FAILED", (response) => {
        this.props.history.push("/checkout");
      });
  };

  raveReceipt = () => {
    const { total, cartItems, rate } = this.props;
    let { processingFees } = this.props;
    const { currency } = this.state;

    // const subTotal = total.toFixed(2) * this.props.rate[0].rate;
    // const salesTax = subTotal * 0.05;
    // const youPay = +subTotal + +salesTax;

    // const currencyDisplay = currency === "KES" ? "kes" : "$";
    let subTotal;

    let salesTax;
    let youPay;

    if (rate) {
      processingFees =
        currency === "USD"
          ? +processingFees.toFixed(2)
          : +processingFees.toFixed(2) * +rate[0].rate;
      subTotal =
        currency === "USD"
          ? +total.toFixed(2)
          : +total.toFixed(2) * +rate[0].rate;
      salesTax =
        currency === "USD"
          ? (subTotal + processingFees) * 0.05 + 0.3
          : (subTotal + processingFees) * 0.04;
      // salesTax = ((subTotal + processingFees) * 0.05) + (currency === "USD" ? 0.35 : 0.35 * +rate[0].rate);
      youPay = +subTotal + +processingFees + salesTax;
    }
    return {
      subTotal: subTotal,
      salesTax: salesTax.toFixed(2),
      totalAmount: youPay.toFixed(2),
      processingFees: processingFees.toFixed(2),
      currency: currency,
      items: cartItems,
      means: "rave",
      createdAt: new Date(),
    };
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  addItem = (item) => {
    let codesF = [];

    if (this.props.codes) {
      this.props.codes.forEach((derived) => {
        if (
          derived.product.toLowerCase() === item.productName.toLowerCase() &&
          derived.price === item.price &&
          derived.currency === item.currency
        ) {
          if (derived.status === false) {
            codesF.push(derived);
          }
        }
      });
    }
    if (this.props.cartItems.length === 0) {
      if (codesF.length === 0) {
        return;
      } else {
        this.props.addItem(item);
      }
    } else {
      if (codesF.length === 0) {
        return;
      } else {
        if (this.props.cartItems) {
          const comparedItemm = this.props.cartItems.find(
            (cartItem) => cartItem.id === item.id
          );
          if (comparedItemm && comparedItemm.quantity >= codesF.length) {
            return;
          } else {
            this.props.addItem(item);
          }
        }
      }
    }
  };

  componentDidMount() {
    if (!this.props.auth.uid) {
      toast.info("Please login to continue with your purchase!", {
        autoClose: 6000,
      });
    }
    let storage = firebase.storage();
    let storageRef = storage.ref("images/utally_assets.jpg");
    storageRef.getDownloadURL().then((url) => {
      this.setState({
        logo: url,
      });
    });
  }
  render() {
    const {
      auth,
      cartItems,
      total,
      saveOrder,
      clearCart,
      rate,
      removeItem,
      decrementItem,
    } = this.props;
    let processingFees = this.props.processingFees;

    // if (!auth.uid) return <Redirect to="/" />;
    const { currency } = this.state;
    const currencyDisplay = currency === "KES" ? "kes" : "$";
    let subTotal;

    let salesTax;
    let youPay;

    if (rate) {
      processingFees =
        currency === "USD"
          ? +processingFees.toFixed(2)
          : +processingFees.toFixed(2) * +rate[0].rate;
      subTotal =
        currency === "USD"
          ? +total.toFixed(2)
          : +total.toFixed(2) * +rate[0].rate;
      salesTax =
        currency === "USD"
          ? (subTotal + processingFees) * 0.05 + 0.3
          : (subTotal + processingFees) * 0.04;
      // salesTax = ((subTotal + processingFees) * 0.05) + (currency === "USD" ? 0.35 : 0.35 * +rate[0].rate);
      youPay = +subTotal + +processingFees + salesTax;
    }

    const paypalSubTotal = +subTotal;
    const paypalSalesTax = salesTax;
    const paypalAmount = +subTotal + +processingFees + salesTax;

    const paypalReceipt = () => {
      return {
        subTotal: paypalSubTotal,
        salesTax: paypalSalesTax.toFixed(2),
        totalAmount: paypalAmount.toFixed(2),
        currency: "USD",
        processingFees: processingFees,
        items: cartItems,
        means: "paypal",
        createdAt: new Date(),
      };
    };

    const mpesaButton = {
      borderRadius: "20px",
      display: "inline-block",
      textAlign: "center",
      height: "100%",
      backgroundColor: "transparent",
      margin: "0px  auto",
      width: "100%",
      outline: "none",
      border: "1px solid grey",
    };

    return (
      <div className="container mt-5 pt-3">
        <ToastContainer hideProgressBar={true} position="top-right" />
        <h2 className="text-white mt-5 display-4">Cart</h2>
        <div className="row">
          <div className="col-md-7">
            <table className="table table-borderless">
              <thead>
                <tr className="pb-5 mb-4">
                  <th style={{ verticalAlign: "middle", fontSize: "14px" }}>
                    Product
                  </th>
                  <th
                    className="pl-5"
                    style={{ verticalAlign: "middle", fontSize: "14px" }}
                  >
                    Qty
                  </th>
                  <th style={{ verticalAlign: "middle", fontSize: "14px" }}>
                    Total price
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cartItems &&
                  cartItems.map((item) => (
                    <tr className="tr-body" key={item.id}>
                      <td>
                        <div className="row">
                          <div className="col-md-4 col-sm-12 col-xs-12">
                            <img
                              src={
                                item.productName.toLowerCase() === "spotify"
                                  ? "/spotify-bg.jpg"
                                  : "/psn-bg.jpg"
                              }
                              className="product-img mr-2 mb-4"
                              alt=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </div>
                          <div
                            className="col-md-8 col-sm-10 col-xs-12 text-white mt-2 pl-4"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "11px",
                            }}
                          >
                            {item.productName.toLowerCase() === "spotify"
                              ? "Spotify"
                              : "PSN"}{" "}
                            -<br />
                            {item.description}
                            <br></br>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="bg-transparent btn rounded-pill p-2 quantity-btn-holder">
                          <button
                            className="border-0 btn text-white bg-transparent"
                            onClick={() => decrementItem(item)}
                          >
                            -
                          </button>
                          <button className="border-0 btn text-white bg-transparent lead">
                            {item.quantity}
                          </button>
                          <button
                            className="border-0 btn text-white bg-transparent"
                            onClick={() => this.addItem(item)}
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td>
                        {item.currency}
                        {item.price}
                      </td>
                      <td className="remove" onClick={() => removeItem(item)}>
                        X
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-5 col-sm-12 col-xs-12">
            <div
              className="card  bg-transparent  payment-card"
              style={{ width: "100%" }}
            >
              <h4 className="text-white ml-4 display-4 checkout-info">
                Checkout info.
              </h4>
              {!auth.uid ? (
                <p className="text-danger text-center">
                  Please login to continue your purchase!
                </p>
              ) : null}

              <div className="form-group w-75  ml-4 bg-transparent mt-3">
                <label htmlFor="currency" className="text-white">
                  Select payment method:
                </label>
                <select
                  className="form-control  text-black "
                  id="currency"
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Choose your payment method
                  </option>
                  <option value="KES">MPESA/CARD</option>
                  <option value="USD">PAYPAL</option>
                </select>
              </div>

              <div className="w-75 ml-4 mt-5 text-white pt-5">
                <div className="row">
                  <div className="col-6">
                    <h5 className="amount-details">Sub Total</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="amount-details">
                      {currencyDisplay}
                      {subTotal && subTotal.toFixed(2)}
                    </h5>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <h5 className="amount-details">Processing fees</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="amount-details">
                      {" "}
                      {currencyDisplay}
                      {processingFees && cartItems.length > 0
                        ? processingFees.toFixed(2)
                        : 0}
                    </h5>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <h5 className="amount-details">Sales Tax</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="amount-details">
                      {" "}
                      {currencyDisplay}
                      {salesTax && cartItems.length > 0
                        ? salesTax.toFixed(2)
                        : 0}
                    </h5>
                  </div>
                </div>
                <hr className="mt-2 mb-3 bg-white" />
                <div className="row mt-2">
                  <div className="col-6">
                    <h5 className="amount-details">You Pay</h5>
                  </div>

                  <div className="col-6">
                    <h5 className="amount-details">
                      {" "}
                      {currencyDisplay}
                      {youPay && cartItems.length > 0 ? youPay.toFixed(2) : 0}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-3 w-100 pt-5">
                {cartItems.length > 0 && currency === "KES" && auth.uid ? (
                  <div className="w-75">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      style={mpesaButton}
                      onClick={() =>
                        this.onMpesaPay(youPay ? youPay.toString() : "10")
                      }
                    >
                      Pay with Mpesa/Card
                    </button>
                    {/* <Rave
                      pay_button_text='Pay using Mobile Money/Card'
                      class='rave-button'
                      amount={youPay ? youPay.toString() : '10'}
                      payment_method='both'
                      customer_email={
                        profile.email ? profile.email : 'email@example.com'
                      }
                      ravePubKey={this.state.key}
                      callback={this.callback}
                      onclose={this.close}
                      country='KE'
                      redirect_url='/thanks'
                      customer_firstname={profile.firstName}
                      customer_lastname={profile.lastName}
                      currency={currency}
                      custom_title='U-tally'
                      custom_logo={this.state.logo}
                    /> */}
                  </div>
                ) : null}
              </div>
              <div className="ml-4 w-100">
                {cartItems.length > 0 && currency === "USD" && auth.uid ? (
                  <div className="w-75">
                    <PayPalButton
                      style={{
                        layout: "horizontal",
                        shape: "pill",
                        size: "large",
                        height: 40,
                      }}
                      amount={paypalAmount.toFixed(2)}
                      shippingPreference="NO_SHIPPING"
                      onSuccess={(details) => {
                        if (details.status === "COMPLETED") {
                          // OPTIONAL: Call your server to save the transaction
                          saveOrder(paypalReceipt());
                          clearCart();
                          this.props.history.push("/thanks");
                        }
                      }}
                      onCancel={() => {
                        this.props.history.push("/checkout");
                      }}
                      catchError={(err) => {
                        console.log(err);

                        setTimeout(() => {
                          this.props.history.push("/checkout");
                        }, 4500);
                      }}
                      options={{
                        clientId:
                          "AfZzLIUE8uCtGHTZrP9pawB7QOYEkpjsj_o9FbsLFPXdjUBn6DGJT-hSoFjiYuy1G7i6mJp44dRGG7Q0",
                        currency: "USD",
                        commit: true,
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    cartItems: state.cart.cartItems,
    rate: state.firestore.ordered.conversion,
    total: state.cart.cartItems.reduce((accumulator, item) => {
      let amt;
      if (item.currency === "R") {
        amt = accumulator + item.quantity * Math.ceil(+item.price * 0.071);
      } else if (item.currency === "£") {
        amt = accumulator + item.quantity * Math.ceil(+item.price * 1.32);
      } else {
        amt = accumulator + item.quantity * +item.price;
      }
      return amt;
      // return accumulator + item.quantity * +item.price;
    }, 0),
    processingFees: state.cart.cartItems.reduce((accumulator, item) => {
      return accumulator + item.quantity * +item.activationFees;
    }, 0),
    codes: state.firestore.ordered.codes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveOrder: (items, receipt_amount) =>
      dispatch(saveOrder(items, receipt_amount)),
    clearCart: () => dispatch(clearCart()),
    removeItem: (item) => dispatch(removeItem(item)),
    addItem: (item) => dispatch(addItemToCart(item)),
    decrementItem: (item) => dispatch(decrementItem(item)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => [
    {
      collection: "codes",
    },
    { collection: "conversion" },
  ])
)(Checkout);
