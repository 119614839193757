import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";

function Thanks(props) {
    const { auth } = props
    if (!auth.uid) {
        window.$('#loginModal').modal('show');

    }
    if (!auth.uid) return <Redirect to="/" />;
    return (
        <div className="container">
            <div className="mx-auto text-center mt-4 pt-5 text-success">
                <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "40px" }}></i>

            </div>
            <div className="mx-auto text-center mt-4 pt-3 text-white">
                <h1>Thank you.</h1>
                <p className="lead">Your payment has been processed successfully.<br /> You'll get an email receipt shortly with your order details and activation key(s). <br />You can also find your activation keys <Link to="/profile">here.</Link></p>
            </div>

        </div>
    )
}
const mapStateToProps = (state) => {

    return {
        auth: state.firebase.auth,


    };
};

export default connect(mapStateToProps)(Thanks);
