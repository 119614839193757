import React from 'react'
import moment from "moment";
import { connect } from "react-redux";
import { update_counter_admin } from "../../store/actions/authActions";
import { update_counter } from "../../store/actions/cartActions";


import './Notification.css'

class Notification extends React.Component {

    handleOrderDetails = (e, notification) => {
        this.props.history.push(`/order/${notification.order_id}`)
        window.$('.dropdown-notifications').dropdown('hide')
        window.$('[data-toggle="tooltip"]').tooltip('hide')

    }
    handleClickedDate = (e) => {
        const dropdown = document.querySelector('.dropdown-notifications')

        if (!dropdown.classList.contains('show') && this.props.history.location.pathname === '/admin') {
            this.props.update_counter_admin()




        } else if (!dropdown.classList.contains('show') && this.props.history.location.pathname !== '/admin') {
            this.props.update_counter()

        }
        if (dropdown.classList.contains('show')) {
            window.$('.dropdown-notifications').dropdown('hide')

        } else {
            window.$('.dropdown-notifications').dropdown('show')

        }



    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false)
    }
    handleClick = (e) => {

        if (e.target.classList.contains('outside')) {
            return

        }
        this.handleClickOutside()

    }
    handleClickOutside = () => {
        window.$('.dropdown-notifications').dropdown('hide')

    }



    render() {
        const { my_notifications } = this.props
      
           let count = 0   
        //  if(my_counter){
        //     count = my_notifications && my_notifications.filter(notification => new Date(notification.time.toDate()) > new Date(my_counter.length ?  my_counter[0].counts.toDate() : 0)).length

        //  }
           

          
        

        
        return (
            <div className="outside">
                <div className="dropdown outside">

                    <span className="outside" role="button" id="dropdownMenu2"  onClick={(e) => this.handleClickedDate(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"   fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
                    </svg>
                       
                    </span>

                    {
                        count > 0 ? <span className="badge badge-danger outside"  style={{ top: "-6px", left: "6px" }}>{ count > 9 ? "9+" : count}</span> : null
                    }

                    <ul className="dropdown-menu dropdown-notifications dropdown-menu-right pull-right outside" role="menu" aria-labelledby="dropdownMenu2" >
                        <li role="presentation" className="outside">
                            <p className="outside">Notifications</p>
                        </li>
                        <div className="dropdown-divider outside"></div>
                        <ul className="timeline timeline-icons timeline-sm outside" style={{ margin: "10px", width: "260px" }}>
                            {my_notifications && my_notifications.length > 0 ? my_notifications.map(notification => (
                                <li key={notification.id} className="outside">
                                    <p className="outside">
                                        {notification.customer_firstName} {notification.content}
                                        <span className="timeline-icon outside">{
                                           notification.content === 'Purchase was successfully please check your email for a receipt.' || notification.content === 'made an order.' ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-bag-check text-success" viewBox="0 0 20 20">
                                          <path fillRule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                          <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
                                        </svg> : notification.content === 'registered.' ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-check text-primary" viewBox="0 0 16 16">
                                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                                        <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                        </svg> :  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-speedometer text-info" viewBox="0 0 20 20">
                                        <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z"/>
                                        <path fillRule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z"/>
                                        </svg>
                                                                                }</span>
                                                                                <span className="timeline-date outside">{moment(notification.time.toDate()).fromNow()}  {notification.content === 'Purchase was successfully please check your email for a receipt.' || notification.content === 'made an order.' ? <span className="float-right outside" ><svg title="view details" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-html="true" onMouseDown={(e) => this.handleOrderDetails(e, notification)} width="20" height="20" fill="currentColor" style={{cursor: "pointer"}} className="bi bi-eye" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                        </svg> </span> : null} </span>
                                        {/* <span className="timeline-date outside">{moment(notification.time.toDate()).fromNow()}  {notification.content === 'Purchase was successfully please check your email for a receipt.' || notification.content === 'made an order.' ? <span className="float-right outside" ><i title="view details" data-toggle="tooltip" data-html="true" onMouseDown={(e) => this.handleOrderDetails(e, notification)} className="outside fa fa-eye text-default" style={{ display: "inline-block", fontSize: "1rem", cursor: "pointer" }}></i></span> : null} </span> */}

                                    </p>
                                </li>

                            )) : <li><span className="timeline-icon outside"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 20 20">
                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                          </svg></span>Your have no notifications.</li>}



                        </ul>
                        <li role="presentation" className="outside">
                            <span className="dropdown-menu-header outside"></span>
                        </li>
                    </ul>
                </div>
            </div>

        )

    }

}
const mapDispatchToProps = (dispatch) => {
    return {

        update_counter: () => dispatch(update_counter()),
        update_counter_admin: () => dispatch(update_counter_admin()),

    };
};


export default connect(null, mapDispatchToProps)(Notification);
