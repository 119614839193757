import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logout, login, clearError, resetPassword, register } from "../../../store/actions/authActions";


import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";


import Notification from "../../notifications/Notification"

import "./Navbar.css"

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      down: false,
      authError: "",
      firstNameS: "",
      lastNameS: "",
      emailS: "",
      passwordS: "",
      confirmPassword: "",
      emailL: "",
      passwordL: "",
      emailR: "",
      logo: "",
      error: "",
    }
  }





  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  // Terms and conditions modal
  openTermsAndConditionsModal = () => {
    window.$('#loginModal').modal('hide');
    window.$('#registerModal').modal('hide');
    window.$('#termsAndConditionsModal').modal('show');

  }

  // Register Code
  openRegisterModal = () => {
    this.props.clearAllErrors()
    window.$('#loginModal').modal('hide');
    window.$('#resetModal').modal('hide');
    let inputs = document.querySelectorAll('input');
    let checkboxes = document.querySelectorAll('#checkbox');
    inputs.forEach(
      input => (input.value = "")
    );
    checkboxes.forEach(
      checkbox => (checkbox.checked = false)
    );
    this.setState({
      error: ""
    })
    window.$('#registerModal').modal('show');

  }
  handleSubmitRegister = (e) => {
    this.setState({
      error: ""
    })
    e.preventDefault();

    this.props.clearAllErrors()

    // checking all input fields are correctly field
    if (this.state.passwordS !== this.state.confirmPasswordS) {
      this.setState({
        error: "Password do not match!",
      });
      return;

    }
    this.props.register({
      firstName: this.state.firstNameS,
      lastName: this.state.lastNameS,
      email: this.state.emailS,
      password: this.state.passwordS,
    });
    setTimeout(() => {
      if (!this.props.authError) {
        // registering user to firebase
        window.$('#registerModal').modal('hide');
        // clear all the inputs values 
        let inputs = document.querySelectorAll('input');
        inputs.forEach(
          input => (input.value = "")
        );

        this.setState({
          emailS: "",
          firstNameS: "",
          lastNameS: "",
          passwordS: "",
          confirmPasswordS: "",
          error: "",
        });

      } else {

        this.setState({
          error: this.props.authError
        })


      }

    }, 2000)

  }
  // Reset code
  openResetModal = () => {
    window.$('#loginModal').modal('hide');
    window.$('#registerModal').modal('hide');
    let inputs = document.querySelectorAll('input');
    inputs.forEach(
      input => (input.value = "")
    );

    window.$('#resetModal').modal('show');



  }
  handleSubmitReset = (e) => {
    e.preventDefault();

    this.setState({
      authError: ""
    })
    this.props.clearAllErrors()

    this.props.reset(this.state.emailR);

    setTimeout(() => {
      if (!this.props.authError) {
        window.$('#instructionsModal').modal('show');
        window.$('#resetModal').modal('hide');

        setTimeout(() => {
          // closing the modal
          window.$('#instructionsModal').modal('hide');
          window.$('#loginModal').modal('show');


        }, 15000)



      } else {


        this.setState({
          authError: this.props.authError
        })
      }

    }, 2000)

  }
  // Login code

  openLoginModal = () => {
    this.props.clearAllErrors()
    window.$('#resetModal').modal('hide');
    window.$('#registerModal').modal('hide');
    let inputs = document.querySelectorAll('input');
    inputs.forEach(
      input => (input.value = "")
    );
    window.$('#loginModal').modal('show');


  }
  handleSubmitLogin = (e) => {
    e.preventDefault();
    let inputs = document.querySelectorAll('input');

    this.props.clearAllErrors()

    this.props.login({
      email: this.state.emailL,
      password: this.state.passwordL
    });
    setTimeout(() => {
      if (!this.props.authError) {
        inputs.forEach(
          input => (input.value = "")
        );
        window.$('#loginModal').modal('hide');

      }

    }, 1000)

  };




  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip();

  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    const {
      logout,
      auth,
      profile,
      authError,
      notifications,
      admin_notifications,
      history,
      counter,
      counteradmin
    } = this.props;










    return (
      <div>
        {/* Start Desktop nav */}
       <div className="d-none d-md-block">
        <nav className="navbar fixed-top navbar-light bg-light desktop-nav">
          <Link className="navbar-brand" to="/">
          <img
            src="/utally-l.png"
            id="logo-img"
            height="40px"
            alt=""
          />
          </Link>
          <ul className='mx-auto shop-faqs'>
            <li><Link to='/'>SHOP</Link></li>
            <li><Link to='/faqs'>FAQs</Link></li>

          </ul>
          <ul className='call-actions'>
           {!auth.uid ? <li className=""><button className="btn-enter rounded-pill" onClick={this.openLoginModal}>LOG IN</button></li> : null}
           {!auth.uid ? <li className=""><button className="btn-signup rounded-pill" onClick={this.openRegisterModal}>REGISTER</button></li> : null}
           <li><Link to="/checkout" className="text-white messages"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi  bi-bag" viewBox="0 0 16 16">
            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
            </svg><span className="badge badge-danger" style={{ top: "-7px" }}>{this.props.itemCount}</span></Link></li>
            {auth.uid ? <li><Notification my_notifications={history.location.pathname === '/admin' ? admin_notifications : notifications} my_counter={history.location.pathname === '/admin' ? counteradmin : counter} history={history} /></li> :null }
            {
             auth.uid ? <li>
             <div className="dropdown">
             <span style={{ cursor: "pointer", color: "white" }} data-toggle="dropdown" className="user-action"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
             <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
             <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
             </svg></span>
             <div className="dropdown-menu profile-dropdown dropdown-menu-right mt-0">
                   <Link to="/profile" className="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                     <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                     <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                   </svg> Profile</Link>
                   {
                     profile && profile.admin === true ? <Link to="/admin" className="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi mr-1 bi-shield-lock" viewBox="0 0 16 16">
                     <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                     <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z"/>
                   </svg> Admin</Link> : null
                   }
 
 
                   <div className="dropdown-divider"></div>
                   <span className="dropdown-item" style={{ cursor: "pointer", color: "white" }} onClick={() => logout()}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
                   <path d="M7.5 1v7h1V1h-1z"/>
                   <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                 </svg> Logout</span>
                 </div>
               </div>
 
             </li>
            : null  
            }
            
          </ul>
        </nav>
        </div>



        {/* End Desktop nav */}
       
        {/* Start Mobile nav */}
        <div className="d-md-none container">
        <nav className="navbar fixed-top  navbar-light bg-dark mobile-nav">
        <Link className="" to="/">
          <img
            src="/utally-l.png"
            id="logo-img"
            height="30px"
            alt=""
          />
          </Link>
          <ul className='shop-faqs-mobile'>
          <li><Link to='/' style={{fontSize:'10px'}}>SHOP</Link></li>
          <li><Link to='/faqs' style={{fontSize:'10px'}}>FAQs</Link></li>


          </ul>
          <ul className='call-to-actions-mobile'>
            <li><Link to="/checkout" className="text-white messages"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-bag" viewBox="0 0 16 16">
            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
            </svg><span className="badge badge-danger" style={{ top: "-7px" }}>{this.props.itemCount}</span></Link></li>
            {auth.uid ? <li><Notification my_notifications={history.location.pathname === '/admin' ? admin_notifications : notifications} my_counter={history.location.pathname === '/admin' ? counteradmin : counter} history={history} /></li> :null }
            {
             auth.uid ? <li>
             <div className="dropdown">
             <span style={{ cursor: "pointer", color: "white" }} data-toggle="dropdown" className="user-action"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
             <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
             <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
             </svg></span>
             <div className="dropdown-menu profile-dropdown dropdown-menu-right mt-0">
                   <Link to="/profile" className="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                     <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                     <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                   </svg> Profile</Link>
                   {
                     profile && profile.admin === true ? <Link to="/admin" className="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi mr-1 bi-shield-lock" viewBox="0 0 16 16">
                     <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                     <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z"/>
                   </svg> Admin</Link> : null
                   }
 
 
                   <div className="dropdown-divider"></div>
                   <span className="dropdown-item" style={{ cursor: "pointer", color: "white" }} onClick={() => logout()}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
                   <path d="M7.5 1v7h1V1h-1z"/>
                   <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                 </svg> Logout</span>
                 </div>
               </div>
 
             </li>
            : null  
            }

          </ul>
          {
            !auth.uid ? <ul className='call-to-actions-mobile-auth ml-5'>
            <li className=""><button className="btn-enter-mobile rounded-pill" onClick={this.openLoginModal}>LOG IN</button></li>
            <li className=""><button className="btn-signup-mobile rounded-pill" onClick={this.openRegisterModal}>REGISTER</button></li>
        

          </ul>
         : null
          }
    
            {/* <Link className="" to="/">
          <img
            src="/utally-l.png"
            id="logo-img"
            height="30px"
            alt=""
          />
          </Link>
          <Link to='/' style={{fontSize:'10px'}}>SHOP</Link>
          <Link to='/faqs' style={{fontSize:'10px'}}>FAQs</Link>
          <Link to="/checkout" className="text-white messages"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi  bi-bag" viewBox="0 0 16 16">
            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
            </svg><span className="badge badge-danger" style={{ top: "-7px" }}>{this.props.itemCount}</span></Link> */}

      
            <div className='col'>
            {/* <ul className='shop-faqs-mobile'>
            <li style={{fontSize:'10px'}}><Link to='/'>SHOP</Link></li>
            <li style={{fontSize:'10px'}}><Link to='/faqs'>FAQs</Link></li>

          </ul> */}

            
          
          

          </div>
          {/* <div className='row'>
            <div className='col'>
            <div className='col'>
            <ul className='call-to-actions-mobile'>
          {auth.uid ? <li className=""><button className="btn-enter-mobile rounded-pill" onClick={this.openLoginModal}>LOG IN</button></li> : null}
           {auth.uid ? <li className=""><button className="btn-signup-mobile rounded-pill" onClick={this.openRegisterModal}>REGISTER</button></li> : null}
           <li><Link to="/checkout" className="text-white messages"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi  bi-bag" viewBox="0 0 16 16">
            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
            </svg><span className="badge badge-danger" style={{ top: "-7px" }}>{this.props.itemCount}</span></Link></li>

          </ul>

            </div>

            </div>
              
          </div> */}
         
          
         
       
          

        </nav>
       
        </div>
        {/* End Mobile nav */}
      
      
  
        {/* LOGIN MODAL  */}

        <div id="loginModal" className="modal fade" data-backdrop="">
          <div className="modal-dialog modal-login">
            <div className="modal-content">
              <div className="modal-header">
                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-door-open avatar" fill="white" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M1 15.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM11.5 2H11V1h.5A1.5 1.5 0 0 1 13 2.5V15h-1V2.5a.5.5 0 0 0-.5-.5z" />
                  <path fillRule="evenodd" d="M10.828.122A.5.5 0 0 1 11 .5V15h-1V1.077l-6 .857V15H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117z" />
                  <path d="M8 9c0 .552.224 1 .5 1s.5-.448.5-1-.224-1-.5-1-.5.448-.5 1z" />
                </svg>
                <h4 className="modal-title">Member Login</h4>
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div className="modal-body">
                <form onSubmit={this.handleSubmitLogin}>
                  <div className="form-group">
                    <input type="email" className="form-control" id="emailL" onChange={this.handleChange} placeholder="Email..." required="required" />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" id="passwordL" placeholder="Password" onChange={this.handleChange} required="required" />
                  </div>
                  {
                    authError ? <p className="error-message text-center text-danger">{authError.message}</p> : null
                  }
                  <div className="form-group">
                    <button type="submit" className="btn btn-lg btn-block">Sign in</button>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button className="border-0 dont-have-account" onClick={this.openRegisterModal}>Don't have an account? Sign up here!</button>
                <button className="border-0 forgot-password" onClick={this.openResetModal} data-target="#resetModal" data-toggle="modal">Forgot Password?</button>

              </div>
            </div>
          </div>
        </div>

        {/* RESET PASSWORD MODAL  */}

        <div id="resetModal" className="modal fade" data-backdrop="">
          <div className="modal-dialog modal-login">
            <div className="modal-content">
              <div className="modal-header">
                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-key-fill avatar" fill="white" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
                <p className="text-center lead mt-4">Please enter your email address. We will send you an email
                    to reset your password.</p>
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div className="modal-body">
                <form onSubmit={this.handleSubmitReset}>
                  <div className="form-group">
                    <input type="email" className="form-control" id="emailR" onChange={this.handleChange} placeholder="Email..." required="required" />
                  </div>

                  <p className="error-message text-center">{this.state.authError}</p>
                  <div className="form-group">
                    <button type="submit" className="btn btn-lg btn-block">Send Email</button>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button className="border-0 dont-have-account" onClick={this.openLoginModal}>Go back to Login!</button>


              </div>
            </div>
          </div>
        </div>

        {/* RESET PASSWORD INSTRUCTIONS MODAL */}
        <div id="instructionsModal" className="modal fade" data-backdrop="">
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Confirmation</h4>
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div className="modal-body">
                <p> An email with further instructions was sent to the submitted email address. If you don’t receive a message in 5 minutes, check the junk folder. If you are still experiencing any problems, contact support at support@u-tally.com</p>
              </div>

            </div>
          </div>
        </div>

        {/* SIGN UP MODAL */}

        <div id="registerModal" className="modal fade" data-backdrop="">
          <div className="modal-dialog modal-login">
            <div className="modal-content">
              <div className="modal-header">
                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-emoji-smile avatar" fill="white" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path fillRule="evenodd" d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683z" />
                  <path d="M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                </svg>
                <h4 className="modal-title">Sign up</h4>
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div className="modal-body">
                <form onSubmit={this.handleSubmitRegister}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col"><input type="text" className="form-control" id="firstNameS" placeholder="First Name" onChange={this.handleChange} required="required" /></div>
                      <div className="col"><input type="text" className="form-control" id="lastNameS" placeholder="Last Name" onChange={this.handleChange} required="required" /></div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control" id="emailS" onChange={this.handleChange} placeholder="Email..." required="required" />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" id="passwordS" placeholder="Password" onChange={this.handleChange} required="required" />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" id="confirmPasswordS" placeholder="Confirm Password" onChange={this.handleChange} required="required" />
                  </div>


                  <div className="form-group">
                    <label className="form-check-label text-success"><input type="checkbox" required="required" id="checkbox" /> <span onClick={this.openTermsAndConditionsModal} style={{ cursor: "pointer" }}>I accept the <button className="border-0">Terms of Use</button> &amp; <button className="border-0">Privacy Policy</button></span></label>
                  </div>
                  {
                    this.state.error ? <p className="error-message text-center text-danger">{this.state.error}</p> : null
                  }
                  <div className="form-group">
                    <button type="submit" className="btn btn-lg btn-block">Sign up</button>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button className="border-0 dont-have-account" onClick={this.openLoginModal}>Have an account? Login!</button>


              </div>
            </div>
          </div>
        </div>
      
      </div>





    );
  }
}

const mapStateToProps = (state, ownprops) => {

  return {
    authError: state.auth.authError,
    loginResult: state.auth.loginResult,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notifications: state.firestore.ordered.notificationsusers && state.firestore.ordered.notificationsusers.filter(notification => notification.customer_id === state.firebase.auth.uid),
    admin_notifications: state.firestore.ordered.notifications,
    counter: state.firestore.ordered.counter && state.firestore.ordered.counter.filter(count => count.id === state.firebase.auth.uid),
    counteradmin: state.firestore.ordered.counteradmin && state.firestore.ordered.counteradmin.filter((count) => count.id === state.firebase.auth.uid),
    notificationsToggle: state.auth.notificationsToggle,
    itemCount: state.cart.cartItems.reduce((accumulator, item) => {
      return accumulator + item.quantity;
    }, 0),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    register: (newUser) => dispatch(register(newUser)),
    logout: () => dispatch(logout()),
    login: (creds) => dispatch(login(creds)),
    reset: (email) => dispatch(resetPassword(email)),
    clearAllErrors: () => dispatch(clearError()),

  };
};

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
      { collection: "notificationsusers", orderBy: ["time", "desc"] },
      { collection: "notifications", orderBy: ["time", "desc"] },
      { collection: "counter" },
      { collection: "counteradmin" },
    ])
  )(Navbar)
);
